<template>
  <div class="DevOpsManage">
    <!-- <headers :title="Header_title"></headers> -->
    <div class="DevOps_header" :class="activeIndex ==1?'Container FilterCriteria':'Container2 FilterCriteria' ">
        <div class="HeaderTab">
          <div class="tabs" v-for="(item,index) in List" :key="index" @click="tap(index)" 
          :class="activeIndex==index ? 'active' : ''">{{item.name}}</div>
        </div>
        <div class="gaugeoutfit2">
					<span class="xian"></span>
					<span class="title">快速检索</span>
				</div>
        <div :class="activeIndex ==1?'DevOps_ser':'DevOps_ser2'">
          <!--业务单元-->
          <Activity @selectedLocations="selectedActivity" class="selectStyle"></Activity>
          <!--厂房-->
          <locations :locationId="location" @selectedLocations="selected1Locations" class="selectStyle"></locations>
          <!--事业部-->
          <Division @selectedLocations="selectedDivision" class="selectStyle"></Division>
          <!--车间-->
          <Workshop :AgencyId='DivisionId'  @selectedLocations="selectedWorkshop" class="selectStyle"></Workshop>
          <!--班组-->
          <TeamsGroups :AgencyId='WorkshopId' @selectedLocations="selectedTeamsGroups" class="selectStyle"></TeamsGroups>
          <!--工段-->
          <WorkshopSection :AgencyId='WorkshopId' :WorkTeamId='TeamsGroupsId' @selectedLocations="selectedWorkshopSection" class="selectStyle"></WorkshopSection>
          <!--作业单元-->
          <BusinessUnit :AgencyId='WorkshopId' :WorkTeamId='WorkshopSectionId' @selectedLocations="selectedBusinessUnit" class="selectStyle"></BusinessUnit>
          <!--设备类型-->
          <DeviceType @selectedLocations="selectedDeviceType" class="selectStyle"></DeviceType>
          <!--设备厂商-->
          <EquipmentModel @selectedLocations="selectedEquipmentModel" class="selectStyle"></EquipmentModel>
          <!-- 查询框 -->
          <div class="inputStyle">
          <span class="SelectText">搜索：</span>
          <el-input
              size="mini"
              placeholder="请输入内容"
              suffix-icon="el-icon-search"
            :style="{width:width}"
              v-model="input4">
          </el-input>  
          </div>
          <!--开始时间-->
          <div class="time_row selectTime" v-if="activeIndex == 1">
            <day @selectedDate="selecteBeginTime" :day="BeginTime" :btn="false" label="开始时间"></day>
          </div>
          <!--结束时间-->
          <div class="time_row selectTime" v-if="activeIndex == 1">
            <day @selectedDate="selectedEndTime" :day="EndTime" :btn="false" label="结束时间"></day>
          </div>
          <el-button class="btn" size="small" type="success" @click="Save()">确认</el-button>
        </div>
    </div>
    <el-row :gutter="18">
      <!-- <el-col :span="4"> -->
      <el-col :span="isTreeCell">
        <div :class="activeIndex == 0 ?'grid-content_l1':'grid-content_l2'" v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(1,34,48, 0)">
          <el-tree
            v-if="dataTree.length>0"
            :data="dataTree"
            class="tree"
            :indent="0"
            ref="tree"
            :highlight-current="true"
            node-key="Id"
            default-expand-all
            :current-node-key="EquipmentId"
            :props="defaultProps"
            :check-strictly="true"
            :check-on-click-node="true"
            :expand-on-click-node="false"
            @node-click="handleNodeClick">
          <span slot-scope="{ node, data }" class="custom-tree-node">
              <span v-if="BackgroundImg=='white'">
					<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/branch_normal.png"/>
					<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
					
					<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal.png"/>
					<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
				</span>
				<span v-else>
					<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/normal2.png"/>
					<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
					
					<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal2.png"/>
					<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
				</span>
              <el-tooltip v-if="node.label.length>10" class="item" effect="dark" :content="node.label" placement="top-start">
                <span style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
              </el-tooltip>
              <span v-else style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
			  <span v-if="data.disabled" class="disabled" @click.stop></span>
          </span>
          </el-tree>
          <div class="grid-content-open" v-if="isIconOpen" @click="handleTreeClick">
							<img class="grid-tree-icon" src="../../../static/images/close_icon.png" alt="" srcset="">
					 </div>
          <span v-show="emptyMsg" class="icon">
            <img :src="empty" class="icon-img">
            <div class="icon-text">暂无数据</div>
          </span>
        </div>
      </el-col>
      <!-- <el-col :span="20"> -->
      <el-col :span="isCoentCell" class="grid-cell">
        <div class="grid-content-close" v-if="!isIconOpen" @click="handleTreeClick">
          <img class="grid-tree-icon" src="../../../static/images/open_icon.png" alt="" srcset="">
        </div>
        <BasicDeviceInfor ref="BasicInfor" key="BasicInfor" v-if="activeIndex == 0" :EquipmentId="EquipmentId"
        :DataList="DataList" :CutterData="CutterData" :currentLoca="currentLoca" :BusinessName="BusinessName" :loading0="loading2" :emptyMsg0="emptyMsg2"></BasicDeviceInfor>
        <EquipFaultManagement ref="EquipInfo" key="EquipInfo" v-if="activeIndex == 1" :EquipmentId="EquipmentId"
          :BeginTime="BeginTime" :EndTime="EndTime"
          :currentLoca="currentLoca" :BusinessName="BusinessName" :EquipmentName="EquipmentName"></EquipFaultManagement>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import locations from '@/components/select/locations'
import Activity from '@/components/select/Activity'
import Division from '@/components/select/Division'
import Workshop from '@/components/select/Workshop'
import TeamsGroups from '@/components/select/TeamsGroups'
import WorkshopSection from '@/components/select/WorkshopSection'
import BusinessUnit from '@/components/select/BusinessUnit'
import DeviceType from '@/components/select/DeviceType'
import EquipmentModel from '@/components/select/EquipmentModel'
// import headers from '@/components/header/headermain'
import empty from '@/assets/icons/empty02.png'
import error from '@/assets/icons/error.png'
import BasicDeviceInfor from './BasicDeviceInformation'
import EquipFaultManagement from './EquipmentFaultManagement'
import day from '@/components/date/day'
import { GetEquipmentCollectDataList, GetEquipmentBasicInfos, GetEquipmentWorkOrders } from "@/api/EquipmentOperationMonitoring"; //页面接口
import { formatDate } from '@/common/js/date.js'
export default {
  components:{
    locations,
    Activity,
    Division,
    Workshop,
    TeamsGroups,
    WorkshopSection,
    BusinessUnit,
    DeviceType,
    EquipmentModel,
    // headers,
    BasicDeviceInfor,
    EquipFaultManagement,
    day,
  },
  data() {
    return {
      Header_title:'设备运维管理',
      List:[{
        name:'设备基本信息',
        id:1
      },{
        name:'设备故障管理',
        id:2
      },],
      activeIndex:0,
      location:this.$route.query.locationId,
      DivisionId:'',
      WorkshopId:'',
      input4:'',
		  width:'10vw',
      TeamsGroupsId:'',
		  WorkshopSectionId:'',
      BackgroundImg:sessionStorage.getItem('BgColorType'),

      loading:false,
		  emptyMsg:true,
      empty:empty,
		  error:error,
      dataTree: [],
	  dataTree2: [],
      defaultProps: {
        children: 'Children',
        label: 'Name'
      },
      currentLoca:{},
      BusinessName:'',
      Bgurl:null,
      srcList:[],
      loadImging:false,
      emptyImgMsg:false,
      loading2:false,
      EquipmentId:null,

      DataList:[],
      CutterData:[],
	  emptyMsg2:true,
      EquipmentName:"",
      BeginTime: formatDate(new Date(), 'yyyy-MM-dd'),
		  EndTime: formatDate(new Date(), 'yyyy-MM-dd'),
      SearchId:0,
      SearchTypeId:0,

      isIconOpen:false,//默认关闭
      isTreeCell:0,
      isCoentCell:24,
    }
  },
  watch:{
    '$store.state.testTxt.tips'(newVal,oldVal){
      if(newVal=='white'){
        this.BackgroundImg = 'white';
      }else{
        this.BackgroundImg = 'black';
      }
    },
  },
  mounted() {

  },
  methods: {
    tap(index){
		  this.activeIndex=index;
      this.SearchId = 0;
      this.SearchTypeId = 0;
      this.EquipmentId = null;
      if (this.activeIndex == 0) {
        this.inits();
      }else if(this.activeIndex == 1){
        this.init2s();
      }
		},
    handleTreeClick(){
      this.isIconOpen = !this.isIconOpen;
      if (this.isIconOpen) {
        this.isTreeCell = 4;
        this.isCoentCell = 20;
      }else{
        this.isTreeCell = 0;
        this.isCoentCell = 24;
      }
    },
    selectedActivity(val) {
		  this.ActivityId = val;
		},
    selected1Locations(val,name){
		  this.location = val;
      this.currentLoca.Name = name;
		},
    selectedDivision(val,name){
		  this.DivisionId = val;
		  this.BusinessName = name;
		},
		selectedWorkshop(val){
			this.WorkshopId = val;
		},
		selectedTeamsGroups(val){
			this.TeamsGroupsId = val;
		},
		selectedWorkshopSection(val){
			this.WorkshopSectionId = val;
		},
		selectedBusinessUnit(val){
			this.BusinessUnitId = val;
      this.inits();
		},
		selectedDeviceType(val){
			this.DeviceTypeId = val; 
		},
		selectedEquipmentModel(val){
			this.EquipmentModelId = val; 
		},
    //开始时间-结束时间
    selecteBeginTime(val) {
      this.BeginTime = formatDate(new Date(val), 'yyyy-MM-dd');
    },
    selectedEndTime(val) {
      this.EndTime = formatDate(new Date(val), 'yyyy-MM-dd');
    },
    //确定
		Save(){
      if (this.activeIndex == 0) {
          this.inits();
      }else if(this.activeIndex == 1){
        if (new Date(this.BeginTime) > new Date(this.EndTime)) {
          this.$notify.info({
            title: '消息',
            message: '开始时间不能大于结束时间'
          });
          return;
        }
          this.init2s(true);
      }
		},
    handleNodeClick (data,e1,e2) {
		  // console.log(data,e1,e2)
      if(data.Type==5){
        if (this.activeIndex == 0) {
          this.EquipmentId = data.Id//监控调用设备图片预览
          this.GetEquipmentDataLists(data.Id)//右侧信息
        }else if(this.activeIndex == 1){
          if (new Date(this.BeginTime) > new Date(this.EndTime)) {
            this.$notify.info({
              title: '消息',
              message: '开始时间不能大于结束时间'
            });
            return;
          }
          this.EquipmentId = data.Id//监控调用设备图片预览
          this.SearchId = data.Id;
          this.SearchTypeId = data.Type;
          this.EquipmentName = data.Name;
        }
      }
		},
    inits(){
			this.loading = true;
			this.emptyMsg = false;
			this.dataTree = [];
			this.dataTree2 = [];
      let params = {
        AgencyId:sessionStorage.getItem('agencyId'),//机构id
        BuAgencyId:this.DivisionId,//事业部
        WorkShopId:this.WorkshopId,//车间
        WorkClassId:this.TeamsGroupsId,//班组
        WorkSectionId:this.WorkshopSectionId,//工段
        WorkUnitId:this.BusinessUnitId,//作业单元
        EquipmentTypeId:this.DeviceTypeId,//,//设备类型
        ModelId:this.EquipmentModelId,//厂商id-设备型号
        LocationId:this.location,//厂房
        KeyWords:this.input4,//关键字
      }
			GetEquipmentBasicInfos(params).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.loading = false;
          this.emptyMsg = true;
          this.dataTree = [];
		  this.dataTree2 = [];
          this.$notify.info({
            title:'消息',
            message: '查询失败'
          });
          return;
        }else if(obj.WorkShopList.length == 0) {
          this.loading = false;
          this.emptyMsg = true;
          this.dataTree = [];
		  this.dataTree2 = [];
          this.$notify.info({
            title: '消息',
            message:'数据为空'
          });
          return;
        }
        this.loading = false;
        this.emptyMsg = false;
        this.dataTree = obj.WorkShopList;
        this.DataList = obj.BasicInfo.RealtimeData;
		//刀具信息
		this.CutterData = obj.BasicInfo.CutterData;
		if(this.CutterData.length>0){
			this.emptyMsg2 = false;
		}else{
			this.emptyMsg2 = true;   
		}

        this.EquipmentId = obj.EquipmentId;
        this.currentLoca.Level = this.dataTree[0].Name;
		this.dataTree2 = this.getNewTree(this.dataTree);
        this.$nextTick(()=>{
          this.$refs.tree.setCurrentKey(this.EquipmentId);
        })
			}).catch(err => {
        this.loading = false;
        this.emptyMsg = true;
			})
		},
    init2s(stuBool){
			this.loading = true;
			this.emptyMsg = false;
			this.dataTree = [];
			this.dataTree2 = [];
      let params = {
        AgencyId:sessionStorage.getItem('agencyId'),//机构id
        BuAgencyId:this.DivisionId,//事业部
        WorkShopId:this.WorkshopId,//车间
        WorkClassId:this.TeamsGroupsId,//班组
        WorkSectionId:this.WorkshopSectionId,//工段
        WorkUnitId:this.BusinessUnitId,//作业单元
        EquipmentTypeId:this.DeviceTypeId,//,//设备类型
        ModelId:this.EquipmentModelId,//厂商id-设备型号
        LocationId:this.location,//厂房
        KeyWords:this.input4,//关键字
        SearchId:this.SearchId,
        SearchTypeId:this.SearchTypeId,
        StrBeginDate: this.BeginTime,
        StrEndDate: this.EndTime
      }
			GetEquipmentWorkOrders(params).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.loading = false;
          this.emptyMsg = true;
          this.dataTree = [];
		  this.dataTree2 = [];
          this.$notify.info({
            title:'消息',
            message: '查询失败'
          });
          return;
        }else if(obj.WorkShopList.length == 0) {
          this.loading = false;
          this.emptyMsg = true;
          this.dataTree = [];
		  this.dataTree2 = [];
          this.$notify.info({
            title: '消息',
            message:'数据为空'
          });
          return;
        }
        this.loading = false;
        this.emptyMsg = false;
        this.dataTree = obj.WorkShopList;

        this.EquipmentId = obj.EquipmentId;
        this.EquipmentName = obj.EquipmentName;
        
        this.currentLoca.Level = this.dataTree[0].Name;
		this.dataTree2 = this.getNewTree(this.dataTree);
        this.$nextTick(()=>{
          this.$refs.tree.setCurrentKey(this.EquipmentId);
          if (stuBool) {
            this.$refs.EquipInfo.HandEqWorkOrders(this.EquipmentId, true)//两个列表
          }
        })
			}).catch(err => {
        this.loading = false;
        this.emptyMsg = true;
			})
		},
		// 递归遍历树,通过map遍历直接修改原数组数据，数组其他的数据不变
		getNewTree(obj){
			obj.map(item=>{
			  if(item.Children&&item.Children.length>0){
				item.disabled = true;
				this.getNewTree(item.Children)
			  }
			})
			return obj
		},
    GetEquipmentDataLists(id){
		this.loading2 = true;
		this.emptyMsg2 = false;
	GetEquipmentCollectDataList({
		AgencyId:sessionStorage.getItem('agencyId'),
		EquipmentId:id
	}).then(res => {
        let obj = res.Content;
        if(res.Code==-1){
          this.DataList = [];
		  this.CutterData = [];
          this.loading2 = false;
		  this.emptyMsg2 = true;
          this.$notify.info({
            title: '消息',
            message: '获取信息失败'
          });
          return;
        }else if(obj.length==0){
          this.DataList = [];
          this.CutterData = [];
		  this.emptyMsg2 = true;
          this.$notify.info({
            title: '消息',
            message:'暂无数据'
          });
          this.loading2 = false;
          return;
        }
        this.DataList = obj.RealtimeData;
        //刀具信息
        this.CutterData = obj.CutterData;
		if(this.CutterData.length>0){
			this.emptyMsg2 = false;
		}else{
			this.emptyMsg2 = true;   
		}
        this.loading2= false;
		}).catch(err => {
			this.loading2= false;
			this.emptyMsg2 = true;
			this.DataList = [];
			this.CutterData = [];
		})
		},
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.inputStyle{
	display: inline-block;
}
.SelectText{
	display:inline-block;
	width: 2.7vw;
	text-align: left;
	padding-left: 16px;
	font-size: 16px;
}
.btn{
	margin-left: 20px;
}
.el-tree{
	padding: 10px;
	font-size: 15px;
}
.time_row{
	display: inline-flex;
	font-size: 16px;
}
/*tree的虚线样式*/
.el-tree{
	padding: 10px;
	font-size: 15px;
	background-color: transparent;
	@include font_color("font2_color_white");
}
/*tree的虚线样式*/
::v-deep .tree{
  .el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 10px; // 缩进量
  }
  .el-tree-node__expand-icon{
	margin-left: 4px!important;
  }
  .tree-element {
  	background-color: transparent;
  	outline: none;
  	cursor: default;
  }
  .el-tree-node__content{
    &:hover{
      background-color: transparent;
      color: #1dac5d;
    }
  }
  // 竖线
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: 3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #bbbbbb;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: "";
    width: 15px;
    height: 20px;
    position: absolute;
    left:3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #bbbbbb;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }
	
  // 展开关闭的icon
  .el-tree-node__expand-icon{
    font-size: 15px;
    // 叶子节点（无子节点）
    &.is-leaf{
      color: transparent;
      // display: none; // 也可以去掉
      &:before{
        display: none;
      }
    }
  }
  .el-tree-node:focus>.el-tree-node__content{ //选中
    background-color: transparent;
  }
}
.custom-tree-node {
  position: relative;
}
.grid-cell{
  position: relative;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
  .disabled {
    display: inline-block;
    cursor: not-allowed;
    position: absolute;
    height: 27px;
    width: 210px;
    background: transparent!important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
  .Container{
    width: 100%;
    // height: 230px;
    height: 270px;
    border-radius: 4px;
  }
  .Container2{
    width: 100%;
    // height: 180px;
    height: 220px;
    border-radius: 4px;
  }


  .DevOps_ser{
    background: url('../../../static/images/search.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 170px;
  }
  .DevOps_ser2{
    background: url('../../../static/images/search.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120px;
  }
  .HeaderTab{
    width: 98%;
    height: 40px;
    margin-left: 15px;
    line-height: 40px;
    font-size:18px;
    // @include border_color("border_color_white");
    margin-bottom: 20px;
  }
  .selectTime{
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .selectStyle{
    margin-right: 10px;
    margin-top: 20px;
  }
  .tabs{
    min-width:100px;
    padding-left: 0px;
    padding-right: 0px;
    height: 40px;
    line-height: 40px;
    width: 240px;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    background: url('../../../static/images/tab_normal.png');
    // background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .active{
    color: #3bffc3!important;
    // border-bottom: 3px solid #3bffc3;
    background: url('../../../static/images/tab_active.png');
    // background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .grid-content_l1{
    position: relative;
    width: 100%;
    height: 645px;
    border-radius: 5px;
    margin-top: 20px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-content_l2{
    position: relative;
    width: 100%;
    height: 595px;
    border-radius: 5px;
    margin-top: 20px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-tree-icon{
    width: 10px;
    height: 12px;
    display: inline-block;
  }

  .grid-content-open{
    width: 18px;
    height: 80px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(100%, -60%);
    cursor: pointer;
    background-color: #062e3a;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .grid-content-close{
    width: 18px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-4px, -60%);
    cursor: pointer;
    background-color: #062e3a;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
}

@media only screen and (min-aspect-ratio: 1920/1000 ) {
	.disabled {
	  display: inline-block;
	  cursor: not-allowed;
	  position: absolute;
	  height: 2.7vh;
	  width: 210px;
	  background: transparent!important;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  z-index: 9;
	}
  .Container{
    width: 100%;
    height: 27vh;
    border-radius: 4px;
  }
  .Container2{
    width: 100%;
    height: 22vh;
    border-radius: 4px;
  }

  .DevOps_ser{
    background: url('../../../static/images/search.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 17vh;
  }
  .DevOps_ser2{
    background: url('../../../static/images/search.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 12vh;
  }
  .selectTime{
    margin-right: 10px;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .selectStyle{
    margin-right: 10px;
    margin-top: 2vh;
  }
  .HeaderTab{
    width: 98%;
    height: 4vh;
    margin-left: 15px;
    line-height: 4vh;
    font-size:18px;
    // @include border_color("border_color_white");
    margin-bottom: 2vh;
  }
  .tabs{
    min-width:100px;
    padding-left: 0px;
    padding-right: 0px;
    height: 4vh;
    line-height: 4vh;
    width: 240px;
    background: url('../../../static/images/tab_normal.png');
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  .active{
    color: #3bffc3!important;
    // border-bottom: 3px solid #3bffc3;
    background: url('../../../static/images/tab_active.png');
    // background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .grid-content_l1{
    position: relative;
    width: 100%;
    height: 64.5vh;
    border-radius: 5px;
    margin-top: 20px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-content_l2{
    position: relative;
    width: 100%;
    height: 59.5vh;
    border-radius: 5px;
    margin-top: 20px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-tree-icon{
    width: 10px;
    height: 12px;
    display: inline-block;
  }

  .grid-content-open{
    width: 18px;
    height: 8vh;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(100%, -60%);
    cursor: pointer;
    background-color: #062e3a;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .grid-content-close{
    width: 18px;
    height: 8vh;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-4px, -60%);
    cursor: pointer;
    background-color: #062e3a;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
}
</style>