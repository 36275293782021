<template>
  <div class="StatusDetails">
    <div class="grid_content_r">
      <el-row>
        <div class="gaugeoutfit2">
          <span class="xian"></span>
          <span class="title">设备故障管理</span>
        </div>
        <el-col :span="7">
          <div class="monitor_1">
            当前位置：<span>{{currentLoca.Name}}</span> > <span>{{currentLoca.Level}}</span>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="monitor_r">
            <div class="StateStyle">
              <span class="title">所属事业部：</span>
              <span class="num">{{ BusinessName }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <!--内容区域-->
      <el-row :gutter="18" class="DevOps_row">
        <el-col :span="5">
          <div class="Regional_l">
            <div
              class="RegionalImg"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(1,34,48, 0)"
              v-if="Bgurl != '' || Bgurl != null"
            >
              <el-image
                class="img"
                :src="Bgurl"
                :preview-src-list="srcList"
              ></el-image>
              <span v-show="emptyMsg" class="icon">
                <img :src="empty" class="icon-img" />
                <div class="icon-text">暂无数据</div>
              </span>
            </div>
            <div class="RegionalList">
              <span class="span1" v-if="Content.EquipmentStatus==0">
                设备状态：
                <span class="span2" style="color: #ff9600">{{
                  Content.EquipmentStatusName
                }}</span>
              </span>
              <span class="span1" v-if="Content.EquipmentStatus == 3">
                设备状态：
                <span class="span2" style="color: #38cb89">{{
                  Content.EquipmentStatusName
                }}</span>
              </span>
              <span class="span1" v-if="Content.EquipmentStatus == 5">
                设备状态：
                <span class="span2" style="color: #f14848">{{
                  Content.EquipmentStatusName
                }}</span>
              </span>
              <span class="span1" v-if="Content.EquipmentStatus == -1">
                设备状态：
                <span class="span2" style="color: #cccccc">{{
                  Content.EquipmentStatusName
                }}</span>
              </span>
            </div>
            <div class="RegionalList">
              <span class="span1"
                >设备名称：<span class="span2">{{
                  Content.EquipmentName
                }}</span></span
              >
            </div>
            <div class="RegionalList">
              <span class="span1"
                >设备厂商：<span class="span2">{{
                  Content.ModelName
                }}</span></span
              >
            </div>
            <div class="RegionalLastList">
              <span class="span1"
                >设备型号：<span class="span2">{{
                  Content.EquipmentTypeName
                }}</span></span
              >
            </div>
          </div>
        </el-col>
        <el-col :span="19" class="DevOps_right">
          <div class="DevOps_gz_yj">
            <div class="DevOps_top_row DevOps_top_gz">
              <img
                class="DevOps_top_img"
                src="../../../static/images/baseInfo/jrgz_icon.png"
                alt=""
              />
              <div class="DevOps_top_right">
                <div class="DevOps_top_item1">
                  <div class="DevOps_item_text">故障</div>
                  <div class="DevOps_item_numl">{{equipErrorView.ErrorAmount}}</div>
                </div>
                <div class="DevOps_top_item">
                  <div class="DevOps_item_text">已处理</div>
                  <div class="DevOps_item_numl">{{equipErrorView.Processed}}</div>
                </div>
                <div class="DevOps_top_item">
                  <div class="DevOps_item_text">待处理</div>
                  <div class="DevOps_item_numl">{{equipErrorView.WaitToProcess}}</div>
                </div>
              </div>
            </div>
            <!-- <div class="DevOps_top_row DevOps_top_bj">
              <img
                class="DevOps_top_img"
                src="../../../static/images/baseInfo/jryj_icon.png"
                alt=""
              />
              <div class="DevOps_top_right">
                <div class="DevOps_top_item1">
                  <div class="DevOps_item_text">报警</div>
                  <div class="DevOps_item_numr">{{equipAlarmView.AlarmsAmount}}</div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="DevOps_gz_cont">
            <div class="DevOps_btns">
              <div class="HeaderTab">
                <div
                  class="tabs"
                  v-for="(item, index) in List"
                  :key="index"
                  @click="tap(index)"
                  :class="tableType == index ? 'active' : ''"
                >
                  {{ item.name }}
                </div>
              </div>
              <el-button class="addbtn" v-if="tableType == 0" size="small" type="success" @click="handleAddGzgd">新建故障工单</el-button>
            </div>
            <div class="DevOps_table_box">
              <el-table
                class="DevOps_sbgz_tab DevOps_table"
                v-show="tableType == 0"
                :data="sbgzList"
                :header-cell-style="{ 'background':BackgroundColors}"
              >
                <el-table-column type="index" label="编号"> </el-table-column>
                <el-table-column prop="WorkOrderType" label="工单类型">
                </el-table-column>
                <el-table-column prop="WorkOrderContent" label="工单内容" width="150">
                  <template slot-scope="scope">
                    <span class="DevOps_gdnr_row">{{scope.row.WorkOrderContent}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="CreatetTimeStr" label="工单日期" width="150">
                </el-table-column>
                <el-table-column prop="Creator" label="提交人"></el-table-column>
                <el-table-column prop="Status" label="工单状态">
                  <template slot-scope="scope">
                    <div v-if="scope.row.Status == '1'" class="DevOps_untreated">
                      待处理
                    </div>
                    <div v-if="scope.row.Status == '2'" class="DevOps_processed">
                      已处理
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="Handler" label="处理人"> </el-table-column>
                <el-table-column prop="FinishTimeStr" label="处理时间" width="150">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                      <span v-if="scope.row.IsShowOperateButton" class="czBtn" @click="handleClClick(scope.row)" type="text" size="small">处理</span>
                      <span v-else class="czBtn" @click="handleViewClick(scope.row)" type="text" size="small">查看</span>
                    </template>
                </el-table-column>
              </el-table>
              <!-- <el-table
                class="DevOps_sbyj_tab DevOps_table"
                v-show="tableType == 1"
                :data="sbyjList"
                :header-cell-style="{ 'background':BackgroundColors}"
              >
                <el-table-column type="index" label="编号" width="100"> </el-table-column>
                <el-table-column prop="AlarmCode" label="报警编号">
                </el-table-column>
                <el-table-column prop="AlarmDescription" label="报警内容">
                </el-table-column>
                <el-table-column prop="CreateTimeStr" label="报警时间">
                </el-table-column>
              </el-table> -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 新建 -->
    <el-dialog
      class="addDialog"
      title="新建故障工单"
      :visible="true"
      width="40%"
      v-if="dialogAddVisible"
      :before-close="handleAddClose">
      <el-form ref="addform" :model="addform" label-width="100px" size="small">
        <div class="form_row">
          <el-form-item label="设备名称:">
            <el-input v-model="addform.EquipmentName" disabled :style="{width:width}"></el-input>
          </el-form-item>
          <el-form-item label="设备位置:">
            <el-input v-model="addform.LocationName" disabled :style="{width:width}"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="工单类型:" class="rule_label">
          <el-select v-model="gdlxValue" placeholder="请选择活动区域" :style="{width:width}">
            <el-option :label="item.WorkOrderType" :value="item.Id" 
            v-for="(item) in gdlxList" :key="item.Id"></el-option>
          </el-select>
          <!-- <span class="rule_icon">*</span> -->
        </el-form-item>
        <el-form-item label="工单描述:" class="rule_label">
          <el-input type="textarea" v-model="addform.WorkOrderContent" :autosize="{ minRows: 4, maxRows: 4}"></el-input>
          <!-- <span class="rule_icon">*</span> -->
        </el-form-item>
        <el-form-item label="上传附件:">
            <!-- accept=".xls,.xlsx,.jpg,.png,.mp4,.doc,.docx" -->
          <el-upload
            ref="addUpload"
            action="#"
            :auto-upload="false"
            :multiple="true"
            :file-list="fileAddList"
            :before-upload="handleAddBeforeUp"
            :on-change="handleAddChange"
            :on-remove="handleAddRemove"
            class="upload_add">
            <el-button size="small" type="success">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">（可上传照片、视频或文档）</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="提交人:">
          <el-input type="input" v-model="addform.tjr" disabled :style="{width:width}"></el-input>
        </el-form-item>
      </el-form>
      <div class="centerbtn">
        <el-button type="success" size="small" @click="handleAddConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 处理 -->
    <el-dialog
      class="addDialog"
      title="故障工单处理"
      :visible="true"
      width="45%"
      v-if="dialogClVisible"
      top="8vh"
      :before-close="handleClClose">
      <el-form ref="Clform" :model="Clform" label-width="100px" size="small">
        <div class="form_row">
          <el-form-item label="设备名称:">
            <el-input v-model="Clform.EquipmentName" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备位置:">
            <el-input v-model="Clform.LocationName" disabled></el-input>
          </el-form-item>
        </div>
        <div class="form_row">
          <el-form-item label="工单类型:">
            <el-input v-model="Clform.WorkOrderType" disabled></el-input>
          </el-form-item>
          <el-form-item label="工单状态:">
            <el-input v-model="Clform.gdzt" :style="{width:width}" disabled></el-input>
          </el-form-item>
        </div>
        <el-form-item label="工单描述:">
          <el-input type="textarea" v-model="Clform.WorkOrderContent" disabled :autosize="{ minRows: 4, maxRows: 4}"></el-input>
        </el-form-item>
        <div class="form_row">
          <el-form-item label="工单时间:">
            <el-input v-model="Clform.CreatetTimeStr" disabled :style="{width:width}"></el-input>
          </el-form-item>
          <el-form-item label="提交人:">
            <el-input type="input" v-model="Clform.Creator" disabled :style="{width:width}"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="附件:">
          <div class="fileform">
            <div>
              <div class="fileitem filemarbot"
                v-for="(item,index) in CommitList" :key="index"> 
                <el-link @click="handleDownloadFile(item.MinioName,item.FileName)" class="fileCure"
                >{{item.FileName}}</el-link>
                <span class="span_time">提交时间:{{resTimeObj.CreateTime}}</span>
              </div>
            </div>
            <div>
              <div class="fileitem" :class="index != ProcessList.length -1 ?'filemarbot':''" 
                v-for="(item,index) in ProcessList" :key="index"> 
                <el-link @click="handleDownloadFile(item.MinioName,item.FileName)" class="fileCure"
                >{{item.FileName}}</el-link>
                <span class="span_time">处理时间:{{resTimeObj.FinishTime}}</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="工单处理:">
          <el-input type="textarea" v-model="Clform.ProcessRemark" :autosize="{ minRows: 4, maxRows: 4}"></el-input>
        </el-form-item>
        <el-form-item label="上传附件:">
            <!-- accept=".xls,.xlsx,.jpg,.png,.mp4,.doc,.docx" -->
          <el-upload
            ref="clUpload"
            action="#"
            :auto-upload="false"
            :multiple="true"
            :before-upload="handleClBeforeUp"
            :on-change="handleClChange"
            :on-remove="handleClRemove"
            :file-list="fileClList"
            class="upload_add">
            <el-button size="small" type="success">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">（可上传照片、视频或文档）</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="处理人:">
          <el-input type="input" v-model="Clform.Handler" disabled></el-input>
        </el-form-item>
      </el-form>
      <div class="centerbtn">
        <el-button type="success" size="small" @click="handleClConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog
      class="addDialog"
      title="故障工单详情"
      :visible="true"
      width="45%"
      v-if="dialogXqVisible"
      top="8vh"
      :before-close="handleXqClose">
      <el-form ref="Xqform" :model="Xqform" label-width="100px" size="small">
        <div class="form_row">
          <el-form-item label="设备名称:">
            <el-input v-model="Xqform.EquipmentName" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备位置:">
            <el-input v-model="Xqform.LocationName" disabled></el-input>
          </el-form-item>
        </div>
        <div class="form_row">
          <el-form-item label="工单类型:">
            <el-input v-model="Xqform.WorkOrderType" disabled></el-input>
          </el-form-item>
          <el-form-item label="工单状态:">
            <el-input v-model="Xqform.gdzt" :style="{width:width}" disabled></el-input>
          </el-form-item>
        </div>
        <el-form-item label="工单描述:">
          <el-input type="textarea" v-model="Xqform.WorkOrderContent" disabled :autosize="{ minRows: 4, maxRows: 4}"></el-input>
        </el-form-item>
        <div class="form_row">
          <el-form-item label="工单时间:">
            <el-input v-model="Xqform.CreatetTimeStr" disabled :style="{width:width}"></el-input>
          </el-form-item>
          <el-form-item label="提交人:">
            <el-input type="input" v-model="Xqform.Creator" disabled :style="{width:width}"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="工单处理:">
          <el-input type="textarea" v-model="Xqform.ProcessRemark" disabled :autosize="{ minRows: 4, maxRows: 4}"></el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <div class="fileform">
            <div>
              <div class="fileitem filemarbot"
                v-for="(item,index) in CommitList" :key="index"> 
                <el-link @click="handleDownloadFile(item.MinioName,item.FileName)" class="fileCure"
                >{{item.FileName}}</el-link>
                <span class="span_time">提交时间:{{resTimeObj.CreateTime}}</span>
              </div>
            </div>
            <div>
              <div class="fileitem" :class="index != ProcessList.length -1 ?'filemarbot':''" 
                v-for="(item,index) in ProcessList" :key="index"> 
                <el-link @click="handleDownloadFile(item.MinioName,item.FileName)" class="fileCure"
                >{{item.FileName}}</el-link>
                <span class="span_time">处理时间:{{resTimeObj.FinishTime}}</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <div class="form_row">
          <el-form-item label="处理人:">
            <el-input type="input" v-model="Xqform.Handler" disabled></el-input>
          </el-form-item>
          <el-form-item label="处理时间:">
            <el-input type="input" v-model="Xqform.FinishTimeStr" disabled></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="centerbtn">
        <el-button type="success" size="small" @click="handleXqConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { encryptDes, decryptDes } from '@/common/js/des.js'
import empty from "@/assets/icons/empty02.png";
import error from "@/assets/icons/error.png";
import {
  GetEquipmentImage,
  GetSelectedEquipmentMonitor,
  AddEquipmentWorkOrder,
  UpdateEquipmentWorkOrder,
  GetEquipmentOrder,
  GetWorkOrderContentType,
  DownloadWorkOrderFile,
  GetEqWorkOrders,
} from "@/api/EquipmentOperationMonitoring"; //页面接口

export default {
  props:{
    serchObj:Object,
    currentLoca:Object,
    BusinessName:String,
    EquipmentId:Number,
    EquipmentName:String,
    BeginTime:String,
    EndTime:String,
  },
  data() {
    return {
      empty: empty,
      error: error,
      sbgzList: [],
      sbyjList: [],
      List: [
        {
          name: "设备故障列表",
          id: 1,
        },
        // {
        //   name: "设备报警列表",
        //   id: 2,
        // },
      ],
      tableType: 0,
      Content: {},
      defaultProps: {
        children: "Children",
        label: "Name",
      },
      width:'10vw',
      loading: false,
      emptyMsg: true,
      Bgurl: null,
      srcList: [],
      dialogAddVisible:false,
      addform:{},
      gdlxValue:null,
      gdlxList:[],
      fileAddList: [],
      fileClList: [],
      fileCkList: [],
      equipErrorView:{
        ErrorAmount:0,
        Processed:0,
        WaitToProcess:0,
      },
      equipAlarmView:{
        AlarmsAmount:0,
      },
      workFileList:[],
      CommitList:[],//提交时间
      ProcessList:[],//处理时间
      resTimeObj:{
        CreateTime:'',
        FinishTime:'',
      },//时间对象

      dialogClVisible:false,
      Clform:{},

      dialogXqVisible:false,
      Xqform:{},
      BgColor:this.$store.state.testTxt.tips,
      BackgroundColors:sessionStorage.getItem('BgColorType')=='white'?'#f9f9f9':'#2a343b',
      maxSize: 10 * 1024 * 1024, // 最大文件大小（10MB）
    };
  },
  watch: {
    EquipmentId(newVal){
      if (newVal) {
        this.GetEquipmentImages(newVal)
        this.GetSelectedMonitors(newVal)
        this.HandleOrderContentType();//工单类型
        this.HandEqWorkOrders(newVal, true)//两个列表
      }
    },
    '$store.state.testTxt.tips'(newVal,oldVal){
			this.BgColor = newVal;
			if(this.BgColor=='white'){
				this.BackgroundColors = '#f9f9f9';
			}else{
				this.BackgroundColors = '#2a343b';
			}
		},
  },
  mounted() {

  },
  methods: {
    tap(index) {
      this.tableType = index;
    },
    HandEqWorkOrders(id, isAll) {
      this.sbgzList = [];
      this.LocationName = "";
      if (isAll) {
        this.sbyjList = [];
      }
      GetEqWorkOrders({
        AgencyId: sessionStorage.getItem('agencyId'),//机构id,
        EquipmentId: id,
        StrBeginDate: this.BeginTime,
        StrEndDate: this.EndTime
      })
        .then((res) => {
          let obj = res.Content;
          if(res.Code==-1) {
            this.$notify.info({
              title:'消息',
              message: '列表查询失败'
            });
            return;
          }
          this.sbgzList = obj.EquipmentWorkOrders;
          this.LocationName = obj.LocationName;
          if (isAll) {
            this.sbyjList = obj.EquipmentAlarms;
            this.equipErrorView = obj.EquipmentErrorOverview;
            this.equipAlarmView = obj.EquipmentAlarmsOverview;
          }
        })
        .catch((err) => {

        });
    },
    GetEquipmentImages(id) {
      this.loading = true;
      this.emptyMsg = false;
      this.srcList = [];
      GetEquipmentImage({
        EquipmentId: id,
      })
        .then((res) => {
          if (res.Code != 0) {
            this.$notify.info({
              title: "消息",
              message: "图片获取为空",
            });
            this.emptyMsg = true;
            return;
          }
          this.Bgurl = "data:image/png;base64," + res.Content;
          this.srcList.push(this.Bgurl);
          this.loading = false;
        })
        .catch((err) => {});
    },
    GetSelectedMonitors(id){
			this.Content = {};
			GetSelectedEquipmentMonitor({
			  AgencyId:sessionStorage.getItem('agencyId'),//机构id
			  EquipmentId:id
			}).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.Content = {};
          this.$notify.info({
            title:'消息',
            message: '查询失败'
          });
          return;
        }else if(obj.MonitorList.length == 0) {
        this.Content = {};
          this.$notify.info({
            title: '消息',
            message:'数据为空'
          });
          return;
        }
        this.Content = obj.MonitorList[0];
      }).catch(err => {
        
      })
		},
    //新增设备故障列表
    HandleOrderContentType(){
      GetWorkOrderContentType({}).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '获取工单类型失败!'
          });
          return;
        }
        this.gdlxList = obj;
      }).catch(err => {
        
      })
    },
    //新增设备故障列表
    UpdateEquipmentWorkOrder(){

    },
    //获取设备故障列表 及 文件列表
    HandEquipmentOrder(id){
      this.CommitList = []
      this.ProcessList = []
      GetEquipmentOrder({
        workOrderId: id
      }).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '获取工单信息失败'
          });
          return;
        }
        if (obj.Commitfiles) {
          this.CommitList = obj.Commitfiles;//创建时间
        }
        if (obj.Processfiles) {
          this.ProcessList = obj.Processfiles;//处理时间
        }
        this.resTimeObj.CreateTime = obj.CreateTime;
        this.resTimeObj.FinishTime = obj.FinishTime;
      }).catch(err => {
        
      })
    },
    //获取设备故障列表
    handleDownloadFile(fileName,trueName){
      // console.log(fileName,'下载文件名称');
      // window.open(window.apiUrl.serverPath+
      //   'api/Equipment/DownloadWorkOrderFile?fileName='
      //   +encodeURIComponent(fileName)
      // ,'_self')


      let x = new window.XMLHttpRequest();
      x.open('GET', window.apiUrl.serverPath+
        '/api/Equipment/DownloadWorkOrderFile?fileName='
        +encodeURIComponent(fileName), true);
      x.responseType = 'blob';
      x.onload = () => {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement('a');
        a.href = url;
        a.download = trueName;
        a.click();
      };
      x.send();
    },
    // 新增-------
    handleAddGzgd(){
      this.dialogAddVisible = true;
      // 置空
      this.gdlxValue = null;
      this.addform = {}//清空表单
      this.fileAddList = [];
      // 赋值
      this.addform.EquipmentName = this.EquipmentName
      this.addform.LocationName = this.LocationName
      this.addform.tjr = decryptDes(sessionStorage.getItem("User"),'engo1234');
    },
    handleAddClose(){
      this.dialogAddVisible = false;
      // 清空
      this.gdlxValue = null;
      this.addform = {}//清空表单
      this.fileAddList = []
    },
    handleAddConfirm(){
      if (!this.gdlxValue) {
        this.$notify.info({
          title:'消息',
          message: '请选择工单类型!'
        });
        return
      }
      if (!this.addform.WorkOrderContent) {
        this.$notify.info({
          title:'消息',
          message: '请输入工单描述!'
        });
        return
      }
      let sizeAdd = 0;
      for (let index = 0; index < this.fileAddList.length; index++) {
        const ele = this.fileAddList[index];
        sizeAdd += ele.size; 
      }
      // console.log(sizeAdd,'文件大小',this.maxSize);
      // console.log(sizeAdd > this.maxSize);
      if (sizeAdd > this.maxSize) {
        this.$notify.info({
          title:'消息',
          message: '单次至多上传10M'
        });
        return
      }
      let formData = new FormData();
      for (let i = 0; i < this.fileAddList.length; i++) {
        if(this.fileAddList[i].raw){
          formData.append('AttachFiles', this.fileAddList[i].raw)
        }
      }
      formData.append('EquipmentId',this.EquipmentId)
      formData.append('WorkOrderTypeId',this.gdlxValue)
      formData.append('WorkOrderContent',this.addform.WorkOrderContent)
      formData.append('Creator',this.addform.tjr)
      // console.log(formData.getAll("AttachFiles"));

      AddEquipmentWorkOrder(formData,{'Content-Type':'application/x-www-form-urlencoded'}).then(res => {
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            // message: '新建工单失败'
            message: res.Content
          });
          return;
        }
        this.$notify.info({
          title:'消息',
          message: '新建工单成功'
        });
        this.dialogAddVisible = false;
        this.HandEqWorkOrders(this.EquipmentId, false)//两个列表
      }).catch(err => {
        
      })
    },
    handleAddChange(file, fileList) {
      // console.log(file, fileList,'add------');
      this.fileAddList = fileList;
    },
    handleAddRemove(file, fileList) {
      // console.log(file, fileList,'remove------');
      this.fileAddList = fileList;
    },
    handleAddBeforeUp(file){
      // console.log(file,'新增文件上传前',file.type);
      // debugger
      if (file.type == "image/jpeg" || file.type == "image/png" 
        || file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
        || file.type == "application/vnd.ms-excel"
        || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        || file.type == "application/msword"
        || file.type == "video/mp4") {
        return true;
      }else{
        this.$notify.info({
          title: '消息',
          type:'warning',
          message:'请上传jpg/png，xls/xlsx，doc/docx，mp4格式文件'
        });
        return false;
      }
    },
    // 处理--------
    handleClClick(item){
      this.dialogClVisible = true
      // 置空
      this.fileClList = []
      // 赋值
      this.Clform = Object.assign({},item)
      if (item.Status == '1') {//待处理
        this.Clform.gdzt = '待处理'
      }else if (item.Status == '2'){//已处理
        this.Clform.gdzt = '已处理'
      }
      this.Clform.Handler = decryptDes(sessionStorage.getItem("User"),'engo1234');
      this.HandEquipmentOrder(item.Id)
    },
    handleClClose(){
      this.dialogClVisible = false
      // 置空
      this.fileClList = []
    },
    handleClConfirm(){
      let sizeAdd = 0;
      for (let index = 0; index < this.fileClList.length; index++) {
        const ele = this.fileClList[index];
        sizeAdd += ele.size; 
      }
      // console.log(sizeAdd,'文件大小',this.maxSize);
      // console.log(sizeAdd > this.maxSize);
      if (sizeAdd > this.maxSize) {
        this.$notify.info({
          title:'消息',
          message: '单次至多上传10M'
        });
        return
      }

      let formData = new FormData();
      for (let i = 0; i < this.fileClList.length; i++) {
        if(this.fileClList[i].raw){
          formData.append('AttachFiles', this.fileClList[i].raw)
        }
      }
      formData.append('Id',this.Clform.Id)
      formData.append('Handler',this.Clform.Handler)
      formData.append('ProcessRemark',this.Clform.ProcessRemark)
      // console.log(formData.getAll("AttachFiles"));

      UpdateEquipmentWorkOrder(formData,{'Content-Type':'application/x-www-form-urlencoded'}).then(res => {
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '处理工单失败'
          });
          return;
        }
        this.$notify.info({
          title:'消息',
          message: '处理工单成功'
        });
        this.HandEqWorkOrders(this.EquipmentId, false)
        this.dialogClVisible = false
      }).catch(err => {
        
      })
    },
    handleClChange(file, fileList) {
      this.fileClList = fileList;
    },
    handleClRemove(file, fileList) {
      this.fileClList = fileList;
    },
    handleClBeforeUp(file){
      // console.log(file,'处理文件上传前');
      if (file.type == "image/jpeg" || file.type == "image/png" 
        || file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
        || file.type == "application/vnd.ms-excel"
        || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        || file.type == "application/msword"
        || file.type == "video/mp4") {
        return true;
      }else{
        this.$notify.info({
          title: '消息',
          type:'warning',
          message:'请上传jpg/png，xls/xlsx，doc/docx，mp4格式文件'
        });
        return false;
      }
    },
    // 查看详情 -----
    handleViewClick(item){
      this.dialogXqVisible = true
      this.Xqform = Object.assign({},item)
      if (item.Status == '1') {//待处理
        this.Xqform.gdzt = '待处理'
      }else if (item.Status == '2'){//已处理
        this.Xqform.gdzt = '已处理'
      }
      this.HandEquipmentOrder(item.Id)
    },
    handleXqClose(){
      this.dialogXqVisible = false
    },
    handleXqConfirm(){
      this.dialogXqVisible = false

    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.StatusDetails {
  width: 100%;
  height: 100%;
  background: url('../../../static/images/bck_bord_big.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.gaugeoutfit .title {
  display: inline-block;
  margin-left: 25px;
}
.DevOps_item_text {
  font-size: 14px;
  @include font_color("font9_color_white");
}
.DevOps_item_numl {
  font-size: 24px;
  @include font_color("font11_color_white");
  // font-weight: 600;
  font-family: 'GemunuLibre-Regular';
}
.DevOps_item_numr {
  font-size: 24px;
  @include font_color("font12_color_white");
  font-weight: 600;
}
.DevOps_untreated {
  font-size: 14px;
  color: #ff9600;
}
.DevOps_processed {
  font-size: 14px;
  color: #38cb89;
}
.addbtn{
  margin-right: 15px;
}
.centerbtn{
  text-align: center;
}
.form_row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fileform{
  max-height: 150px;
  overflow: auto;
}
.fileitem{
  font-size: 14px;
  @include background_color("background3_color_white");
  padding: 0 10px;
}
.fileVideo{
  width: 120px;
  height: 120px;
}
.fileCure{
  cursor: pointer;
}
.fileitem_img{
  display: inline-block;
  width: 60px;
  height: 60px;
}
.filemarbot{
  margin-bottom: 3px;
}
.czBtn{
  font-size: 14px;
  color: #38cb89;
  cursor: pointer;
}
.DevOps_gdnr_row{
  white-space: nowrap;
}
// 文件下载 列表展示
.fileitem {
  display: flex;
  justify-content: space-between;
  align-items: center
}
.fileitem .span_time{
  @include font_color("font_color_hover_white");
}
.DevOps_top_gz{
  background: url('../../../static/images/sbgzgl_gz.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
.DevOps_top_bj{
  background: url('../../../static/images/sbgzgl_yj.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
.DevOps_row{
  padding: 0 15px 15px;
}
.DevOps_right{
  @include background_color("background_color_white");
}
@media only screen and (max-aspect-ratio: 1920/920) {
  .gaugeoutfit {
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .gaugeoutfit .xian {
    display: inline-block;
    width: 3px;
    height: 20px;
    border-radius: 2px;
    position: relative;
    left: 15px;
    top: 5px;
    background-color: rgb(55, 202, 137);
  }
  .el-tree {
    padding: 10px 10px 10px 10px;
    font-size: 15px;
  }
  .TreeImg {
    display: inline-block;
    width: 16px;
    height: 17px;
  }
  .grid_content_r {
    width: 100%;
    border-radius: 5px;
    // @include background_color("background_color_white");
    margin-top: 20px;
  }
  .DevOps_gz_cont {
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_h.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .DevOps_table_box{
    height: 275px;
    padding: 0 20px 0;
    margin-top: 20px;
  }
  .DevOps_table {
    height: 100%;
  }
  .DevOps_btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .HeaderTab {
    width: 98%;
    height: 50px;
    margin-left: 15px;
    line-height: 50px;
    font-size: 18px;
    @include border_color("border_color_white");
  }
  .tabs {
    min-width: 100px;
    padding-left: 0px;
    padding-right: 0px;
    height: 50px;
    line-height: 50px;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  .active {
    color: #38cb89 !important;
    border-bottom: 3px solid #38cb89;
  }
  .miniTab {
    min-width: 50px;
    padding-left: 0px;
    padding-right: 0px;
    height: 50px;
    line-height: 50px;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  .monitor_1 {
    width: 100%;
    height: 50px;
    font-size: 14px;
    padding-left: 15px;
    line-height: 50px;
  }
  .monitor_r {
    width: 100%;
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
  }
  .StateStyle {
    display: inline-block;
    width: 135px;
    height: 100%;
  }
  .StateStyle .title {
    @include font_color("font2_color_white");
    font-size: 14px;
  }
  .StateStyle .num {
    @include font_color("font2_color_white");
    font-size: 14px;
  }
  .Regional_l {
    width: 100%;
    // height: 475px;
    height: 485px;
    border-radius: 2px;
    // margin-top: 18px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .RegionalImg {
    position: relative;
    width: 100%;
    height: 248px;
    margin-bottom: 20px;
    text-align: center;
  }
  .RegionalImg .img {
    display: inline-block;
    // width: 78%;
    // height: auto;
    height: calc(100% - 10px);
    // margin-left: 11%;
    // margin-top: 20px;
    cursor: pointer;
    vertical-align: middle;
    padding-top: 10px;
  }
  .RegionalList {
    width: 92%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    @include border_color("border_color_white");
  }
  .RegionalLastList {
    width: 92%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
  }
  .RegionalLastList .span1,
  .RegionalList .span1 {
    font-size: 15px;
    @include font_color("font5_color_white");
  }
  .RegionalLastList .span2,
  .RegionalList .span2 {
    font-size: 15px;
    @include font_color("font_color_white");
  }

  .DevOps_gz_yj {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
  }
  .DevOps_top_row {
    width: 49%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include background_color("background_color_white");
  }
  .DevOps_top_img {
    margin-left: 25px;
  }
  .DevOps_top_right {
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .DevOps_top_item1 {
    width: 40%;
  }
  .DevOps_top_item {
    width: 30%;
  }
}
@media only screen and (min-aspect-ratio: 1920/1000) {
  .gaugeoutfit {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
  }
  .gaugeoutfit .xian {
    display: inline-block;
    width: 3px;
    height: 2vh;
    border-radius: 2px;
    position: relative;
    left: 15px;
    top: 5px;
    background-color: rgb(55, 202, 137);
  }
  .el-tree {
    padding: 10px 10px 10px 10px;
    font-size: 15px;
  }
  .TreeImg {
    display: inline-block;
    width: 16px;
    height: 1.7vh;
  }
  .grid_content_r {
    width: 100%;
    border-radius: 5px;
    // @include background_color("background_color_white");
    margin-top: 20px;
  }
  .DevOps_gz_cont {
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_h.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .DevOps_table_box{
    height: 27.5vh;
    padding: 0 20px 0;
    margin-top: 20px;
  }
  .DevOps_table {
    height: 100%;
  }
  .DevOps_btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .HeaderTab {
    width: 98%;
    height: 5vh;
    margin-left: 15px;
    line-height: 5vh;
    font-size: 18px;
    @include border_color("border_color_white");
  }
  .tabs {
    min-width: 100px;
    padding-left: 0px;
    padding-right: 0px;
    height: 5vh;
    line-height: 5vh;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  .active {
    color: #38cb89 !important;
    border-bottom: 3px solid #38cb89;
  }
  .miniTab {
    min-width: 50px;
    padding-left: 0px;
    padding-right: 0px;
    height: 5vh;
    line-height: 5vh;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  .monitor_1 {
    width: 100%;
    height: 5vh;
    font-size: 14px;
    padding-left: 15px;
    line-height: 5vh;
  }
  .monitor_r {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    white-space: nowrap;
  }
  .StateStyle {
    display: inline-block;
    width: 135px;
    height: 100%;
  }
  .StateStyle .title {
    @include font_color("font2_color_white");
    font-size: 14px;
  }
  .StateStyle .num {
    @include font_color("font2_color_white");
    font-size: 14px;
  }
  .Regional_l {
    width: 100%;
    height: 48.5vh;
    border-radius: 2px;
    // margin-top: 18px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .RegionalImg {
    position: relative;
    width: 100%;
    height: 24.8vh;
    margin-bottom: 20px;
    text-align: center;
  }
  .RegionalImg .img {
    display: inline-block;
    // width: 78%;
    // height: auto;
    height: calc(100% - 10px);
    // margin-left: 11%;
    // margin-top: 2vh;
    cursor: pointer;
    vertical-align: middle;
    padding-top: 10px;
  }
  .RegionalList {
    width: 94%;
    height: 5vh;
    line-height: 5vh;
    margin: 0 auto;
    @include border_color("border_color_white");
  }
  .RegionalLastList {
    width: 94%;
    height: 5vh;
    line-height: 5vh;
    margin: 0 auto;
  }
  .RegionalLastList .span1,
  .RegionalList .span1 {
    font-size: 15px;
    @include font_color("font5_color_white");
  }
  .RegionalLastList .span2,
  .RegionalList .span2 {
    font-size: 15px;
    @include font_color("font_color_white");
  }

  .DevOps_gz_yj {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
  }
  .DevOps_top_row {
    width: 49%;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include background_color("background_color_white");
  }
  .DevOps_top_img {
    margin-left: 25px;
  }
  .DevOps_top_right {
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .DevOps_top_item1 {
    width: 40%;
  }
  .DevOps_top_item {
    width: 30%;
  }
}
</style>
<style lang="scss">
@import '@/assets/mixin.scss';
.DevOps_table .el-table__body-wrapper {
  height: 200px;
  overflow-y: auto;
}
.fileitem .el-link.el-link--default:hover{
  @include font_color("font_color_hover_white");
}
.fileitem .el-link.el-link--default{
  @include font_color("font_color_hover_white");
}
.fileitem .el-link.is-underline:hover:after{
  @include border_bottom_color("font_color_hover_white");
}
.upload_add .el-upload-list{
  max-height: 200px;
  overflow: auto;
}
.upload_add .el-upload-list__item-name{
  @include font_color("font_color_hover_white");
}
.upload_add .el-upload-list__item:hover{
  @include background_color("background3_color_white");
}
// 新增 表单验证 *
.rule_label.el-form-item>.el-form-item__label:before{
  content: '*';
  color: #F56C6C;
  margin-right: 5px;
}
</style>