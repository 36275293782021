<template>
  <div class="DevOpsManage">
    <div class="grid-content_r">
      <div class="gaugeoutfit2">
        <span class="xian"></span>
        <span class="title">设备基本信息</span>
      </div>
      <el-row>
        <el-col :span="7">
          <div class="monitor_1">
          当前位置：<span>{{currentLoca.Name}}</span> > <span>{{currentLoca.Level}}</span>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="monitor_r">
            <div class="StateStyle">
              <span class="title">所属事业部：</span>
              <span class="num">{{BusinessName}}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--内容区域-->
    <el-row :gutter="18" class="DevOps_row">
      <el-col :span="5">
        <div class="Regional_l">
          <div class="RegionalImg" 
          v-loading="loading"
            element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(1,34,48, 0)" v-if="Bgurl!=''||Bgurl!=null">
            <el-image class="img" :src="Bgurl" :preview-src-list="srcList"></el-image>
            <span v-show="emptyMsg" class="icon">
              <img :src="empty" class="icon-img">
              <div class="icon-text">暂无数据</div>
            </span>
          </div> 
          <div class="RegionalList">
            <span class="span1" v-if="Content.EquipmentStatus ==0">
              设备状态：
              <span class="span2" style="color: #ff9600!important;">{{Content.EquipmentStatusName}}</span>
            </span>
            <span class="span1" v-if="Content.EquipmentStatus==3">
              设备状态：
              <span class="span2" style="color: #38cb89!important;">{{Content.EquipmentStatusName}}</span>
            </span>
            <span class="span1" v-if="Content.EquipmentStatus==5">
              设备状态：
              <span class="span2" style="color: #f14848!important;">{{Content.EquipmentStatusName}}</span>
            </span>
            <span class="span1" v-if="Content.EquipmentStatus==-1">
              设备状态：
              <span class="span2" style="color: #cccccc!important;">{{Content.EquipmentStatusName}}</span>
            </span>
          </div>
          <div class="RegionalList">
            <span class="span1">设备名称：<span class="span2">{{Content.EquipmentName}}</span></span>
          </div>
          <div class="RegionalList">
            <span class="span1">设备厂商：<span class="span2">{{Content.ModelName}}</span></span>
          </div>
          <div class="RegionalLastList">
            <span class="span1">设备型号：<span class="span2">{{Content.EquipmentTypeName}}</span></span>
          </div>
        </div>
      </el-col>
      <el-col :span="19">
        <div class="Regional_top" v-loading="loading2"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(1,34,48, 0)">
          <div class="Regional_rtitle Regional_1">
            <div class="Regional_left Regional_item">
              <div class="Regional_ltit">主轴倍率(%)</div>
              <div class="Regional_lnum">{{GetArrayValue('cnc_srate')}}</div>
            </div>
            <div class="Regional_right Regional_item">
              <img class="Regional_rimg" src="../../../static/images/baseInfo/zzbl_icon.png" alt="">
            </div>
          </div>
          <div class="Regional_rtitle Regional_1">
            <div class="Regional_left Regional_item">
              <div class="Regional_ltit">进给倍率(%)</div>
              <div class="Regional_lnum">{{GetArrayValue('pmc_rdpmcrng')}}</div>
            </div>
            <div class="Regional_right Regional_item">
              <img class="Regional_rimg" src="../../../static/images/baseInfo/jjbl_icon.png" alt="">
            </div>
          </div>
          <div class="Regional_rtitle Regional_1">
            <div class="Regional_left Regional_item">
              <div class="Regional_ltit">主轴负荷(N*m)</div>
              <div class="Regional_lnum">{{GetArrayValue('cnc_rdspload')}}</div>
            </div>
            <div class="Regional_right Regional_item">
              <img class="Regional_rimg" src="../../../static/images/baseInfo/zzfh_icon.png" alt="">
            </div>
          </div>
        </div>
        <div class="Regional_r_b">
          <!-- <div class="information inforinit">
            <div class="information_title">基础信息</div>
            <div class="information_con">
              <el-row :gutter="10">
                <el-col :span="5">
                  <div class="mes">
                    <span>设备编号：</span>
                    <span>21002018020</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>设备名称：</span>
                    <span>TV1588-2</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>IP地址：</span>
                    <span>192.168.120.113</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>端口号：</span>
                    <span>-</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>系统版本号：</span>
                    <span>-</span>
                  </div>
                </el-col>
              </el-row>	
              <el-row :gutter="10">
                <el-col :span="5">
                  <div class="mes">
                    <span>数控系统型号：</span>
                    <span>FANUC</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>有效轴数：</span>
                    <span>3</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>最大轴数：</span>
                    <span>3</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div> -->
          <div class="information">
            <div class="information_title">运行数据</div>
            <div class="information_con">
              <el-row :gutter="10">
                <el-col :span="5">
                  <div class="mes">
                    <span>主轴倍率：</span>
                    <span>{{GetArrayValue('cnc_srate')}}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>进给倍率：</span>
                    <span>{{GetArrayValue('pmc_rdpmcrng')}}</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>主轴负荷：</span>
                    <span>{{GetArrayValue('cnc_rdspload')}}</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>主轴转速：</span>
                    <span>{{GetArrayValue('cnc_acts')}}mm/min</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>进给速度：</span>
                    <span>{{GetArrayValue('cnc_actf')}}mm/min</span>
                  </div>
                </el-col>
              </el-row>	
            </div>
          </div>
          <div class="information Axis">
            <div class="information_title">程序信息</div>
            <div class="information_con">
              <el-row :gutter="10">
                <el-col :span="5">
                  <div class="mes" v-ellipsis:topstart="GetArrayValue('cnc_proname')">
                    <span>程序号：</span>
                    <span>{{GetArrayValue('cnc_proname')}}</span>
                  </div>
                </el-col>
                <el-col :span="9">
                  <div class="mes">
                    <span>当前执行代码：</span>
                    <span>{{GetArrayValue('cnc_currpg')}}</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>工作名称：</span>
                    <span>0</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="mes">
                    <span>进给速度：</span>
                    <span>{{GetArrayValue('cnc_actf')}}mm/min</span>
                  </div>
                </el-col>
              </el-row>	
            </div>
          </div>
          <div class="information Axis">
            <div class="information_title">坐标数据</div>
            <div class="information_con">
              <el-row :gutter="10">
                <el-col :span="5">
                  <div class="mes">
                    <span>绝对坐标</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>X：</span>
                    <span>{{GetArrayValue('cnc_rdabsX')}}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>Y：</span>
                    <span>{{GetArrayValue('cnc_rdabsY')}}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>Z：</span>
                    <span>{{GetArrayValue('cnc_rdabsZ')}}</span>
                  </div>
                </el-col>
                <!-- <el-col :span="3">
                  <div class="mes">
                    <span>A：</span>
                    <span>0</span>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="mes">
                    <span>B：</span>
                    <span>0</span>
                  </div>
                </el-col> -->
              </el-row>	
              <el-row :gutter="10">
                <el-col :span="5">
                  <div class="mes">
                    <span>机床坐标</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>X：</span>
                    <span>{{GetArrayValue('cnc_rdmachX')}}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>Y：</span>
                    <span>{{GetArrayValue('cnc_rdmachY')}}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                    <span>Z：</span>
                    <span>{{GetArrayValue('cnc_rdmachZ')}}</span>
                  </div>
                </el-col>
                <!-- <el-col :span="3">
                  <div class="mes">
                    <span>A：</span>
                    <span>0</span>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="mes">
                    <span>B：</span>
                    <span>0</span>
                  </div>
                </el-col> -->
              </el-row>	
            </div>
          </div>
          <div class="information Axis BottomAxis"
		  v-loading="loading0"
		  element-loading-text="加载中"
		  element-loading-spinner="el-icon-loading"
		  element-loading-background="rgba(1,34,48, 0)">
            <div class="information_title">刀具寿命</div>
            <div class="information_con toolManagement" v-if="CutterData.length>0">
              <el-row :gutter="10" v-for="(item,index) in CutterData" :key="index">
                <el-col :span="5">
                  <div class="mes">
                    <span>刀具{{(item.toolNo/1)}}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                   <span>额定寿命：</span>
                   <span>{{item.toolInitLife}}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="mes">
                   <span>当前寿命：</span>
                   <span>{{item.toolLife}}</span>
                  </div>
                </el-col>
              </el-row>	
            </div>
			<span v-show="emptyMsg0" class="icon">
			  <img :src="empty" class="icon-img">
			  <div class="icon-text">暂无数据</div>
			</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import empty from '@/assets/icons/empty02.png'
import error from '@/assets/icons/error.png'
import { GetEquipmentImage, GetSelectedEquipmentMonitor } from "@/api/EquipmentOperationMonitoring"; //页面接口
export default {
  props:{
    serchObj:Object,
    DataList:Array,
    CutterData:Array,
    EquipmentId:Number,
    currentLoca:Object,
    BusinessName:String,
	loading0:{ type:Boolean, default: () => { return true } },
	emptyMsg0:{ type:Boolean, default: () => { return true } },
  },
  components:{
  },
  data() {
    return {
      List:[{
        name:'设备基本信息',
        id:1
      },{
        name:'设备故障管理',
        id:2
      },],
      loading:false,
	  emptyMsg:true,
      empty:empty,
	  error:error,
      defaultProps: {
        children: 'Children',
        label: 'Name'
      },
      Bgurl:null,
      srcList:[],
      Content:{},
      loading2:false,
    }
  },
  watch:{
    EquipmentId(newVal){
      if (newVal) {
        this.GetEquipmentImages(newVal)
        this.GetSelectedMonitors(newVal)
      }
    },
	CutterData(newVal){
		// console.log(newVal,'222')
	}
  },
  mounted() {
	// console.log(this.CutterData)
  },
  methods: {
    GetArrayValue(val){
      if(this.DataList.length>0){
        let result = this.DataList.filter(item=>item.VarNo === val);
        if(result.length>0){
          return result[0].VarVal
        }else{
          return 0
        }
      }
		},
    GetEquipmentImages(id){
			this.loading = true;
			this.emptyMsg = false;
			this.srcList = [];
			GetEquipmentImage({
			    EquipmentId:id
			  }).then(res => {
				  if(res.Code!=0){
					 this.$notify.info({
					   title: '消息',
					   message:'图片获取为空'
					 });
					 this.emptyMsg = true;
					 return;
				  }
				this.Bgurl = 'data:image/png;base64,'+ res.Content;
				this.srcList.push(this.Bgurl);
				this.loading = false;
			}).catch(err => {
			
			})
		},
    GetSelectedMonitors(id){
			this.Content = {};
			GetSelectedEquipmentMonitor({
			  AgencyId:sessionStorage.getItem('agencyId'),//机构id
			  EquipmentId:id
			}).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.Content = {};
          this.$notify.info({
            title:'消息',
            message: '查询失败'
          });
          return;
        }else if(obj.MonitorList.length == 0) {
        this.Content = {};
          this.$notify.info({
            title: '消息',
            message:'数据为空'
          });
          return;
        }
        this.Content = obj.MonitorList[0];
      }).catch(err => {
        
      })
		},
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.gaugeoutfit .title{
	display: inline-block;
	margin-left: 25px;
}
.Regional_1{
  background: url('../../../static/images/sbjbxx.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: 100%;
}
.DevOpsManage{
  background: url('../../../static/images/bck_bord_big.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.DevOps_row{
  padding: 0 15px 15px;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
  .gaugeoutfit{
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .gaugeoutfit .xian{
    display: inline-block;
    width: 3px;
    height: 20px;
    border-radius: 2px;
    position: relative;
    left: 15px;
    top: 5px;
    background-color: rgb(55, 202, 137);
  }
  .grid-content_r{
    width: 100%;
    height: 95px;
    border-radius: 4px;
    margin-top: 20px;
    // @include background_color("background_color_white");
  }
  .monitor_1{
    width: 100%;
    height: 50px;
    font-size: 14px;
    padding-left: 15px;
    line-height: 50px;
  }
  .monitor_r{
    width: 100%;
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
  }
  .StateStyle{
    display: inline-block;
    width: 135px;
    height: 100%;
  }
  .StateStyle .title{
    @include font_color("font2_color_white");
    font-size: 14px;
  }
  .StateStyle .num{
    @include font_color("font2_color_white");
    font-size: 14px;
  }

  .Regional_l{
    width: 100%;
    height: 535px;
    border-radius: 5px;
    // margin-top: 18px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .RegionalImg{
    position: relative;
    width: 100%;
    height: 280px;
    margin-bottom: 20px;
    text-align: center;
  }
  .RegionalImg .img{
    display: inline-block;
    // width: 80%;
    // height: auto;
    height: calc(100% - 10px);
    // margin-left:10%;
    // margin-top: 20px;
    cursor: pointer;
    vertical-align: middle;
    padding-top: 10px;
  }
  .RegionalList{
    width: 92%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    @include border_color("border_color_white");
  }
  .RegionalLastList{
    width: 92%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
  }
  .RegionalLastList .span1,
  .RegionalList .span1{
    font-size: 15px;
    @include font_color("font7_color_white");
  }
  .RegionalLastList .span2,
  .RegionalList .span2{
    font-size: 15px;
    @include font_color("font_color_white");
  }
  
  .Regional_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 18px;
  }
  .Regional_rtitle{
    display: flex;
    justify-content: space-between;
    // height: 70px;
    height: 115px;
    width: calc(32% - 30px);
    padding: 0 15px;
    @include background_color("background_color_white");
    border-radius: 5px;
  }
  .Regional_item{
    width: 50%;
    height: 100%;

  }
  .Regional_ltit{
    @include font_color("font9_color_white");
    font-size: 14px;
    margin-top: 30px;
  }
  .Regional_lnum{
    @include font_color("font10_color_white");
    font-size: 24px;
    // font-weight: 600;
    margin-top: 5px;
    font-family: 'GemunuLibre-Regular';
  }

  .Regional_rimg{
    width: 48px;
    height: 48px;
    display: inline-block;
    float: right;
    margin-top: 30px;
  }
  .gridContent{
    padding-left: 20px;
  }
  .gridContent span:nth-child(1){
    display: block;
    font-size: 16px;
    @include font_color("font_color_white");
    margin-top: 28px;
    margin-bottom: 10px;
  }
  .gridContent span:nth-child(2){
    display: block;
    font-size: 30px;
    font-weight: 500;
    margin-top: 15px;
    color: #1dac5d;
    font-family: 'UniDreamLED';
    font-weight: lighter;
    text-decoration: none;
    background-image: -webkit-linear-gradient(92deg, #1dac5d, #1dac5d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue 60s infinite linear;
    margin-bottom: 10px;
  }

  .Regional_r_b{
    padding: 0 15px 15px 15px;
    margin-top: 18px;
    // @include background_color("background_color_white");
    border-radius: 2px;
    // min-height: 420px;
    min-height: 385px;
    background: url('../../../static/images/baseInfo_h.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .inforinit{
    padding-bottom: 5px;
  }
  .information{
	  width: 100%;
	  min-height:75px;
    @include border_color("border_color_white");
  }
  .BottomAxis{
	  position: relative;
	  border-bottom: none!important;
  }
  .information_title{
	  font-size: 16px;
	  padding-top: 10px;
    @include font_color("font_color_white");
  }
  .information_con .mes{
	  height: 30px;
	  line-height: 30px;
  }
  .Axis .mes{
	  height: 30px;
	  line-height: 30px;
  }
  .Axis2 .mes{
  	  height: 30px;
  	  line-height: 30px;
  }
  .information_con .mes span:nth-child(1){
	  font-size: 14px;
    @include font_color("font7_color_white");
  }
  .information_con .mes span:nth-child(2){
    font-size: 14px;
    @include font_color("font_color_white");
  }
  .toolManagement{
  	max-height: 140px;
  	overflow-y: auto;
  	overflow-x: hidden;  
  }
}

@media only screen and (min-aspect-ratio: 1920/1000 ) {
  .gaugeoutfit{
    width: 100%;
    height: 5vh;
    line-height: 5vh;
  }
  .gaugeoutfit .xian{
    display: inline-block;
    width: 3px;
    height: 2vh;
    border-radius: 2px;
    position: relative;
    left: 15px;
    top: 5px;
    background-color: rgb(55, 202, 137);
  }
  .grid-content_r{
    width: 100%;
    height: 9.5vh;
    border-radius: 4px;
    margin-top: 20px;
    // @include background_color("background_color_white");
  }
  .monitor_1{
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    font-size: 14px;
    padding-left: 15px;
  }
  .monitor_r{
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    white-space: nowrap;
  }
  .StateStyle{
    display: inline-block;
    width: 135px;
    height: 100%;
  }
  .StateStyle .title{
    @include font_color("font2_color_white");
    font-size: 14px;
  }
  .StateStyle .num{
    @include font_color("font2_color_white");
    font-size: 14px;
  }

  .Regional_l{
    width: 100%;
    height: 53.5vh;
    border-radius: 5px;
    // margin-top: 18px;
    // @include background_color("background_color_white");
    background: url('../../../static/images/baseInfo_v.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .RegionalImg{
    position: relative;
    width: 100%;
    height: 28vh;
    margin-bottom: 20px;
    text-align: center;
  }
  .RegionalImg .img{
    display: inline-block;
    // width: 80%;
    // height: auto;
    height: calc(100% - 10px);
    // margin-left:10%;
    // margin-top: 20px;
    cursor: pointer;
    vertical-align: middle;
    padding-top: 10px;
  }
  .RegionalList{
    width: 92%;
    height: 5vh;
    line-height: 5vh;
    margin: 0 auto;
    @include border_color("border_color_white");
  }
  .RegionalLastList{
    width: 92%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
  }
  .RegionalLastList .span1,
  .RegionalList .span1{
    font-size: 15px;
    @include font_color("font7_color_white");
  }
  .RegionalLastList .span2,
  .RegionalList .span2{
    font-size: 15px;
    @include font_color("font_color_white");
  }

  .Regional_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 18px;
  }
  .Regional_rtitle{
    display: flex;
    justify-content: space-between;
    // height: 7vh;
    height: 11.5vh;
    width: calc(32% - 30px);
    padding: 0 15px;
    @include background_color("background_color_white");
    border-radius: 5px;
  }
  .Regional_item{
    width: 50%;
    height: 100%;
  }
  .Regional_ltit{
    @include font_color("font9_color_white");
    font-size: 14px;
    margin-top: 30px;
  }
  .Regional_lnum{
    @include font_color("font10_color_white");
    font-size: 24px;
    // font-weight: 600;
    margin-top: 5px;
    font-family: 'GemunuLibre-Regular';
  }

  .Regional_rimg{
    width: 48px;
    height: 48px;
    display: inline-block;
    float: right;
    margin-top: 30px;
  }
  .gridContent{
    padding-left: 20px;
  }
  .gridContent span:nth-child(1){
    display: block;
    font-size: 16px;
    @include font_color("font_color_white");
    margin-top: 28px;
    margin-bottom: 10px;
  }
  .gridContent span:nth-child(2){
    display: block;
    font-size: 30px;
    font-weight: 500;
    margin-top: 15px;
    color: #1dac5d;
    font-family: 'UniDreamLED';
    font-weight: lighter;
    text-decoration: none;
    background-image: -webkit-linear-gradient(92deg, #1dac5d, #1dac5d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue 60s infinite linear;
    margin-bottom: 10px;
  }

  .Regional_r_b{
    padding: 0 15px 15px 15px;
    margin-top: 18px;
    // @include background_color("background_color_white");
    border-radius: 2px;
    // min-height: 42vh;
    min-height: 38.5vh;
    background: url('../../../static/images/baseInfo_h.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .inforinit{
    padding-bottom: 5px;
  }
  .information{
	  width: 100%;
	  min-height:7.5vh;
    @include border_color("border_color_white");
  }
  .BottomAxis{
	  position: relative;
	  border-bottom: none!important;
  }
  .information_title{
	  font-size: 16px;
	  padding-top: 10px;
    @include font_color("font_color_white");
  }
  .information_con .mes{
	  height: 3vh;
	  line-height: 3vh;
  }
  .Axis .mes{
	  height: 3vh;
	  line-height: 3vh;
  }
  .Axis2 .mes{
  	  height: 3vh;
  	  line-height: 3vh;
  }
  .information_con .mes span:nth-child(1){
	  font-size: 14px;
    @include font_color("font7_color_white");
  }
  .information_con .mes span:nth-child(2){
  	  font-size: 14px;
      @include font_color("font_color_white");
  }
  .toolManagement{
  	max-height: 14vh;
  	overflow-y: auto;
  	overflow-x: hidden;  
  }
}
</style>